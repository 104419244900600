/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000000;
}

/* You can remove these classes if not used */
.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}